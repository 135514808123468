import logo from './logo.svg';
import './App.css';
import React from 'react'

//Report Viewer source
import '@boldreports/javascript-reporting-controls/Scripts/bold.report-viewer.min';
import '@boldreports/javascript-reporting-controls/Content/material/bold.reports.all.min.css';
//Data-Visualization
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.bulletgraph.min';
import '@boldreports/javascript-reporting-controls/Scripts/data-visualization/ej.chart.min';
//Reports react base
import '@boldreports/react-reporting-components/Scripts/bold.reports.react.min';
//Report viewer source



var viewerStyle = {'height': '700px', 'width': '100%'};


function App() {
  return (
    <div className="App">
      <div style={viewerStyle}>
      {/* <BoldReportViewerComponent
      id="reportviewer-container"
      reportServiceUrl = {'http://desktop-7ra9kot/ReportServer'}
     //reportPath = {'~/Resources/docs/sales-order-detail.rdl'} 
     //reportPath = {'~/Resources/report/SSRSReport/Report2.rdl'} 
      >
      </BoldReportViewerComponent> */}
      <div id="container" ></div>
      {/* <iframe src="http://iglobeapps.eastus.cloudapp.azure.com/Reports/browse/">s</iframe> */}
    </div>
    <script >
    window.jQuery(document).ready(function() {

setTimeout(function(){
window.jQuery("#container").boldReportViewer({
            // Specifies the report Web API service URL. It is used to process the reports.
            reportServiceUrl: 'http://iglobeapps.eastus.cloudapp.azure.com/Reports',
            // Specifies the path of the RDL report file
            reportPath: 'Report1.rdl',
            // toolbarSettings: window.Globals.TOOLBAR_OPTIONS,
            // toolBarItemClick: window.Globals.EDIT_REPORT,
            // exportItemClick: window.Globals.EXPORT_ITEM_CLICK
          })
        },1000)
    }); 
    </script>
    
    </div>
  );
}

export default App;
